<template>
  <v-dialog v-model="dialog" max-width="1300px"
            :persistent="loadingUpdateName || loading || loadingImprint || loadingKeywords || loadingCreatePixel || loadingCreateCampaign">

    <v-dialog v-model="isVisibleErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ errorMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmPixel" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Delete Pixel settings for {{ pixelType }}?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="confirmPixel = false">Cancel</v-btn>
          <v-btn color="error" @click.stop="deletePixel" :loading="loadingDeletePixel">
            Delete
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        <!--      <v-card-text>-->
        <!--        <v-container>-->
        <!--          <v-row>-->
        <!--            <v-col cols="12" sm="12" md="12">-->
        <!--              <p class="text-h6">{{ errorMessage }}</p>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-container>-->
        <!--      </v-card-text>-->
      </v-card>
    </v-dialog>


    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        right
        top
        color="green darken-2"
    >
      Copied : {{ snackbarText }}
    </v-snackbar>

    <v-card>
      <v-card-title class="primary" v-if="createNew">
        <span class="headline" ref="dialog">
          Create campaign: '{{ newCampaignName }}'
        </span>
      </v-card-title>
      <v-card-title class="primary" v-else>
        <span class="headline" ref="dialog">
          View campaign Id:
          <v-hover v-slot:default="{ hover }">
              <div :style="{ display: 'inline' }">
                {{ campaignId }}
                <v-icon small
                        :style="{visibility: hover ? 'visible': 'hidden'}"
                        @click.stop="copyToClipboard(campaignId.toString())">
                  mdi-content-copy
                </v-icon>
              </div>
            </v-hover> : '{{ campaignName }}'
        </span>
      </v-card-title>

      <v-card-text>
        <v-tabs v-model="formTab">

          <v-tab href="#tab-info">
            <v-icon>mdi-information-variant</v-icon>
          </v-tab>

          <v-tab href="#tab-chart" v-if="!createNew">
            <v-icon>mdi-chart-bar</v-icon>
          </v-tab>

        </v-tabs>


        <v-tabs-items v-model="formTab">
          <v-tab-item value="tab-info">
            <v-progress-linear v-if="loading"
                               indeterminate
                               color="primary"
            ></v-progress-linear>
            <v-container>
              <v-row v-if="createNew">
                <!--                <v-col cols="12" sm="2">-->
                <!--                  <v-autocomplete-->
                <!--                      v-model="country"-->
                <!--                      :items="countries"-->
                <!--                      hide-details-->
                <!--                      dense-->
                <!--                      solo-->
                <!--                      item-value="code"-->
                <!--                      @change="changeCountry"-->
                <!--                      label="Country"-->
                <!--                      clearable-->
                <!--                  >-->
                <!--                    <template v-slot:prepend>-->
                <!--                      <flag :iso="country"/>-->
                <!--                    </template>-->

                <!--                    <template v-slot:item="{ item }">-->
                <!--                      <flag :iso="item.code" class="mr-3"/>-->
                <!--                      {{ item.text }}-->
                <!--                    </template>-->

                <!--                  </v-autocomplete>-->
                <!--                </v-col>-->
                <v-col cols="12">
                  <v-autocomplete
                      v-model="offer"
                      :items="offers"
                      hide-details
                      return-object
                      dense
                      item-value="id"
                      item-text="name"
                      label="Offer"
                      clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="!createNew">
                <v-col cols="12" sm="12" class="title" justify="center" align="center">
                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=tracking`"
                     target="_blank"
                     style="text-decoration: none"
                     class="mr-4">
                    Tracking
                  </a>
                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=keywords`"
                     target="_blank"
                     style="text-decoration: none"
                     class="mr-4">
                    Keywords
                  </a>
                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=callbacks`"
                     target="_blank"
                     style="text-decoration: none"
                     class="mr-4">
                    Callbacks
                  </a>
                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=settings`"
                     target="_blank"
                     style="text-decoration: none"
                     class="mr-4">
                    Settings
                  </a>
                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=pixel`"
                     target="_blank"
                     style="text-decoration: none"
                     class="mr-4">
                    Pixel
                  </a>
                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=rename`"
                     target="_blank"
                     style="text-decoration: none"
                     class="mr-4">
                    Rename
                  </a>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3">
                  <v-autocomplete
                      v-model="user"
                      :items="users"
                      hide-details
                      label="User"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-text-field
                      label="Offer name"
                      hide-details
                      v-model="name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-autocomplete
                      :items="countries"
                      v-model="country"
                      hide-details
                      item-value="code"
                      label="Country"
                  >
                    <template v-slot:prepend>
                      <flag :iso="country"/>
                    </template>
                    <template v-slot:item="{ item }">
                      <flag :iso="item.code" class="mr-3"/>
                      {{ item.text }}
                    </template>

                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                      label="Postfix"
                      hide-details
                      v-model="postfix"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="createNew">
                <v-col cols="1">
                  <v-switch v-model="enableDomain"></v-switch>
                </v-col>
                <v-col cols="11">
                  <v-text-field
                      prefix="https://wwww."
                      suffix=".bond"
                      label="Domain"
                      hide-details
                      v-model="domain"
                      :disabled="!enableDomain"
                  ></v-text-field>
                  <br>
                  <p v-if="enableDomain"><b>You can now suggest a domain for your campaign. For more information, see
                    info box below.</b></p>
                  <p v-if="enableDomain">By using our self-registration process, you confirm that you understand and
                    agree to comply with
                    all the requirements listed below:
                    <ul>
                      <li>The domain should not include any words, brand-names, acronyms or character combinations that
                        could be considered to infringe any trademark or result in any copyright complaints.
                      </li>
                      <li>The domain should be comprised of terms and real words that relate directly to the chosen
                        offer, geo and adtitle/call to action (CTA) used.
                      </li>
                      <li>The domain should not include any numbers, special characters i.e. accents, or any
                        incomprehensible letter combinationsWe would recommend that the domain is written in the same
                        language as the adtitle or CTA.
                      </li>
                      <li>The domain should not include any special characters i.e. accents.</li>
                      <li>The domain may include hyphens "-" which are contained within the domain name (not at the
                        beginning or end).
                      </li>
                    </ul>
                  </p>

                </v-col>
              </v-row>
              <v-row>
                <v-col justify="center" align="center">
                  <span v-if="createNew" class="subtitle-1 mr-3">Result campaign Name :</span>
                  <span v-if="!createNew" class="subtitle-1 mr-3">Set new name:</span>
                  <span class="text-h5">'{{ newCampaignName }}'</span>
                  <v-btn v-if="!createNew" color="green darken-1" class="ml-3" :disabled="!isAllowUpdateName()"
                         :loading="loadingUpdateName"
                         @click="updateName()">Save
                    <v-icon>mdi-content-save-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!createNew">
                <v-col align="center" cols="2">
                  <v-switch color="green darken-1"
                            v-model="imprint"
                            :loading="loadingImprint"
                            label="Imprint"
                            @change="updateImprint"
                  >
                  </v-switch>
                </v-col>
                <v-col cols="10" class="mt-4">
                  <p class="subtitle-1">
                    Default is <b>yes</b> for EU countries and <b>no</b> for non-EU countries.
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="createNew">
                <v-col cols="2">
                  <v-switch color="green darken-1" v-model="imprint" label="Imprint"></v-switch>
                </v-col>
                <v-col cols="10" class="mt-4">
                  <p class="subtitle-1">
                    Default is <b>yes</b> for EU countries and <b>no</b> for non-EU countries.
                  </p>
                </v-col>
              </v-row>
              <KeywordsEditor v-if="isVisibleKeywords && !createNew" :copyKeywords="copyKeywords"
                              :country="campaign.country"
                              :copyKeywordsFromCampaignId="copyKeywordsFromCampaignId"
                              :campaignId="campaignId"
                              :keyWords="keyWords"
                              :kwAmount="kwAmount"
                              @copy-keywords="onCopyKeywords"></KeywordsEditor>
              <v-row v-if="createNew">
                <v-col justify="center" align="center" class="pt-0 mt-0">
                  <v-btn color="green darken-1" :disabled="!isAllowCreateNewCampaign()" @click="createNewCampaign()"
                         :loading="loadingCreateCampaign">
                    Create
                    campaign
                    <v-icon>mdi-content-save-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    justify="center" align="center"
                >
                  <p></p>
                  <p class="title pa-0 ma-0">Pixels Settings</p>

                  <v-btn-toggle
                      borderless
                      v-model="pixelType"
                      color="primary"

                  >
                    <v-btn value="facebook" :style="{'text-transform':'none !important'}" :disabled="pixelsAllowDelete">
                      Facebook
                    </v-btn>
                    <v-btn value="tiktok" :style="{'text-transform':'none !important'}" :disabled="pixelsAllowDelete">
                      TikTok
                    </v-btn>

                  </v-btn-toggle>
                </v-col>
              </v-row>

              <v-row v-if="pixelType === 'facebook' && pixelsIsReady">
                <v-col
                    class="d-flex"
                    cols="12"
                    sm="3"
                >
                  <v-select
                      :items="facebook_events_name"
                      v-model="pixels.event_name"
                      label="Event Type"
                  ></v-select>
                </v-col>

                <v-col
                    class="d-flex"
                    cols="12"
                    sm="3"
                >
                  <v-text-field
                      v-model="pixels.pixel_id"
                      label="Pixel Id"
                  ></v-text-field>
                </v-col>
                <v-col
                    class="d-flex"
                    cols="12"
                    sm="6"
                >
                  <v-text-field
                      v-model="pixels.domain_verification"
                      label="Domain Verification"
                  ></v-text-field>
                </v-col>

                <v-col
                    class="d-flex"
                    cols="12"
                    sm="12"
                >
                  <v-text-field
                      v-model="pixels.access_token"
                      label="Access Token"
                  ></v-text-field>
                </v-col>

                <v-col
                    class="d-flex"
                    cols="12"
                    sm="12"
                >
                  <v-row>
                    <v-col cols="12"
                           sm="6">
                      <v-switch
                          v-model="pixels.send_revenue"
                          :false-value="'no'"
                          :true-value="'yes'"
                          label="Send revenue pre-estimations in pixel"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12"
                           sm="6">
                      The campaign needs to get some traffic for a few days to get revenue values in the pixel. Please
                      note that this will differ from the estimations later.
                    </v-col>
                  </v-row>

                </v-col>

                <v-col
                    class="d-flex"
                    cols="12"
                    sm="12"
                >
                  <v-row>
                    <v-col cols="12"
                           sm="6">
                      <v-select
                          :items="facebook_deduplication_parameter"
                          v-model="pixels.deduplication_parameter"
                          label="Event Deduplication parameter"
                      ></v-select>
                    </v-col>
                    <v-col cols="12"
                           sm="6">
                      <p>Only necessary for publishers using the Facebook Conversion API</p>
                      <ul>
                        <li>Facebook pixel is fired when a visitor makes an ad-click</li>
                        <li>In order to track all the events, even in the case where a visitor has blocked Facebook, you
                          can upload events through the Facebook Business Manager
                        </li>
                        <li>To avoid having duplicate events, Facebook checks the deduplication parameter against
                          browser conversion events. If these match, the conversion is not counted twice.
                        </li>
                        <li>The default value is random_id which assigns a random id to each click tracked through the
                          browser
                        </li>
                      </ul>

                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-if="pixelType === 'tiktok' && pixelsIsReady">
                <v-col
                    class="d-flex"
                    cols="12"
                    sm="3"
                >
                  <v-select
                      v-if="pixels.events_s2s_conversion === 'no'"
                      :items="tiktik_events_name"
                      v-model="pixels.event_name"
                      label="Event Type"
                  ></v-select>
                </v-col>

                <v-col
                    class="d-flex"
                    cols="12"
                    sm="3"
                >
                  <v-text-field
                      v-model="pixels.pixel_id"
                      label="Pixel Id"
                  ></v-text-field>
                </v-col>
                <!--                <v-col-->
                <!--                    class="d-flex"-->
                <!--                    cols="12"-->
                <!--                    sm="6"-->
                <!--                >-->
                <!--                  <v-text-field-->
                <!--                      v-model="pixels.domain_verification"-->
                <!--                      label="Domain Verification"-->
                <!--                  ></v-text-field>-->
                <!--                </v-col>-->

                <v-col
                    class="d-flex"
                    cols="12"
                    sm="6"
                >
                  <v-text-field
                      v-model="pixels.access_token"
                      label="Access Token"
                  ></v-text-field>
                </v-col>

                <v-col
                    class="d-flex"
                    cols="12"
                    sm="12"
                >
                  <v-row>
                    <v-col cols="12"
                           sm="6">
                      <v-switch
                          v-model="pixels.send_revenue"
                          :false-value="'no'"
                          :true-value="'yes'"
                          label="Send revenue pre-estimations in pixel"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12"
                           sm="6">
                      The campaign needs to get some traffic for a few days to get revenue values in the pixel. Please
                      note that this will differ from the estimations later.
                    </v-col>
                  </v-row>

                </v-col>

                <v-col
                    class="d-flex"
                    cols="12"
                    sm="12"
                >
                  <v-row>
                    <v-col cols="12"
                           sm="6">
                      <v-switch
                          v-model="pixels.events_s2s_conversion"
                          :false-value="'no'"
                          :true-value="'yes'"
                          label="Use new TikTok S2S conversions with default mappings."
                      ></v-switch>
                    </v-col>
                    <v-col cols="12"
                           sm="6">
                      <b>Event mapping:</b><br>
                      We are now sending 4 events to TikTok, one for each track event:<br>
                      <ul>
                        <li>Redirect → PageView</li>
                        <li>View → ClickButton</li>
                        <li>Keyword Click → ViewContent</li>
                        <li>Ad Click → CompletePayment</li>
                      </ul>
                    </v-col>
                  </v-row>

                </v-col>

                <v-col
                    class="d-flex"
                    cols="12"
                    sm="12"
                >
                  <v-row>
                    <v-col cols="12"
                           sm="12">

                      <b>Important:</b><br>
                      You need to append <b>ttclid=__CLICKID__</b> to the end of your URL and insert it at Ad Group-> Ad
                      Details-> URL in TikTok ads. (See <a
                        href="https://bytedance.sg.feishu.cn/docx/doxcnBh35YXOUaBY3cr7bnPoLug">ads.tiktok.com</a>.)<br>
                      <b>How to use the TikTok Pixel:</b><br>
                      Sign in to your TikTok Ads Manager to gather the values that you will use to complete the tag
                      setup:<br>
                      <ul>
                        <li>In your TikTok Ads Manager Account, go to Assets -> Events</li>
                        <li>Click the Manage button for Web Events</li>
                        <li>If not already done create a Pixel:</li>
                        <ol>
                          <li>Select the Set Up Web Events button</li>
                          <li>Type in a name</li>
                          <li>Select Events API as Connection Method and click Next</li>
                          <li>Select Manually Set Up Events API as Installation Type and click Next</li>
                          <li>Select the Go to Settings button in the bottom right corner</li>
                        </ol>
                        <li>If you have a Pixel:</li>
                        <ol>
                          <li>Click on the pixel name</li>
                          <li>Go to the Settings tab</li>
                        </ol>
                        <li>Copy the ID which is written underneath the pixel name and past it as Pixel Id</li>
                        <li>Click the Generate Access Token button under Events API</li>
                        <li>Copy the token and past it as Access Token</li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-col>

              </v-row>

              <v-row v-if="pixelsIsReady">
                <v-col v-if="pixelsAllowDelete">
                  <v-col justify="center" align="right">
                    <v-btn color="red darken-1 mr-3"
                           @click.stop="confirmPixel = true">
                      Delete
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        :disabled="!allowUpdatePixel"
                        :loading="loadingUpdatePixel"
                        @click.stop="updatePixel"
                    >
                      Update
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </v-col>
                </v-col>

                <v-col v-if="pixelIsNew">
                  <v-col justify="center" align="right" class="pt-0 mt-0">
                    <v-btn
                        color="green darken-1 ml-3"
                        :disabled="!allowCreatePixel"
                        @click.stop="createPixel"
                        :loading="loadingCreatePixel"
                    >
                      Save
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </v-col>
                </v-col>

                <!--                <v-col cols="12">-->
                <!--                  <pre>{{ JSON.stringify(pixels, null, 2) }}</pre>-->
                <!--                </v-col>-->
              </v-row>

            </v-container>

          </v-tab-item>

          <v-tab-item value="tab-chart">
            <v-container v-if="statisticIsReady">
              <v-row>
                <v-col cols="12" sm="12">
                  <highcharts class="stock" :options="highchartsOptions"></highcharts>
                </v-col>
              </v-row>
              <v-col cols="12" sm="12">
                <v-data-table
                    hide-default-footer
                    disable-pagination
                    disable-sort
                    :headers="headers"
                    :items="stat"
                    item-key="date"
                >
                </v-data-table>
              </v-col>
            </v-container>
          </v-tab-item>

        </v-tabs-items>

      </v-card-text>
    </v-card>


  </v-dialog>
</template>

<script>
import Highcharts from 'highcharts'
import {DateTime} from 'luxon'
import api from '@/utils/api'
import KeywordsEditor from './KeywordsEditor.vue'

export default {
  name: 'CampaignEditor',
  components: {KeywordsEditor},
  props: {
    value: {
      default: false
    },
    state: {
      default: ''
    },
    users: {
      default: []
    },
    countries: {
      default: []
    },
    offers: {
      default: []
    },
    copyKeywords: {
      default: []
    },
    copyKeywordsFromCampaignId: {
      default: null
    },
    selectedCountry: null,
    campaign: {
      default: {
        clicks: 0,
        country: "",
        cpc: 0,
        id: 0,
        imprint: "no",
        link: "",
        name: "",
        offer: "",
        offer_id: "",
        revenue: 0,
        target: "",
        type: "",
        user: "",
        vertical: ""
      }
    },
    tz: {
      default: ''
    },

    tab: {
      default: 'tab-info'
    }
  },
  data() {
    return {
      isVisibleKeywords: false,
      createNew: false,
      isVisibleErrorDialog: false,
      errorMessage: '',

      snackbar: false,
      snackbarText: '',

      loading: false,

      formTab: this.tab,

      user: null,
      name: '',
      country: null,
      postfix: '',
      imprint: false,

      enableDomain: false,
      domain: '',

      campaignId: 0,
      campaignName: '',
      newCampaignName: '',

      newUser: null,
      newName: null,
      newCountry: null,
      newPostfix: null,
      newImprint: null,
      dialog: this.value,


      keyWordsReady: false,
      keyWords: [],
      kwAmount: 3,

      confirmPixel: false,
      pixelType: null,
      pixelIsNew: false,
      pixelsNewFaceBook: {
        event_name: 'Purchase',
        pixel_id: null,
        domain_verification: null,
        access_token: null,
        send_revenue: 'no',
        deduplication_parameter: 'random_id'
      },

      pixelsNewTikTok: {
        event_name: 'CompletePayment',
        pixel_id: '',
        send_revenue: 'no',
        access_token: '',
        events_s2s_conversion: 'no'
      },
      pixels: {},
      pixelsFromApi: {},
      pixelsIsReady: false,
      pixelsAllowDelete: false,
      pixelsAllowReset: false,
      loadingCreatePixel: false,
      loadingUpdatePixel: false,
      loadingDeletePixel: false,


      loadingUpdateName: false,
      loadingImprint: false,
      loadingKeywords: false,
      loadingCreateCampaign: false,

      facebook_events_name: [
        'AddPaymentInfo',
        'AddToCart',
        'AddToWhitelist',
        'CompleteRegistration',
        'Contact',
        'CustomizeProduct',
        'Donate',
        'FindLocation',
        'InitiateCheckout',
        'Lead',
        'PageView',
        'Purchase',
        'Schedule',
        'Search',
        'StartTrial',
        'SubmitApplication',
        'Subscribe',
        'ViewContent'
      ],

      facebook_deduplication_parameter: [
        'random_id',
        'track_id',
        'subid1',
        'subid2',
        'subid3',
        'subid4'
      ],

      tiktik_events_name: [
        "AddPaymentInfo",
        "AddToCart",
        "AddToWishlist",
        "ClickButton",
        "CompletePayment",
        "CompleteRegistration",
        "Contact",
        "Download",
        "InitiateCheckout",
        "PlaceAnOrder",
        "Search",
        "SubmitForm",
        "Subscribe",
        "ViewContent"
      ],

      statisticIsReady: false,

      offer: null,

      highchartsOptions: {
        chart: {
          zoomType: 'xy'
        },
        credits: {
          enabled: false
        },
        title: null,
        subtitle: null,
        xAxis: [{
          categories: [],
          crosshair: true
        }],
        yAxis: [{ // Primary yAxis
          labels: {
            format: '{value}',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          title: {
            text: 'Clicks',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          }
        }, { // Secondary yAxis
          title: {
            text: 'Revenue',
            style: {
              color: Highcharts.getOptions().colors[0]
            }
          },
          labels: {
            format: '{value} $',
            style: {
              color: Highcharts.getOptions().colors[0]
            }
          },
          opposite: true
        }],
        tooltip: {
          shared: true
        },
        legend: {
          align: 'left',
          x: 80,
          verticalAlign: 'top',
          y: 80,
          floating: true,
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || // theme
              'rgba(255,255,255,0.25)'
        },
        series: [
          {
            name: 'Revenue',
            type: 'column',
            yAxis: 1,
            // pointInterval: 1000 * 3600 * 24,
            // data: [27.6, 28.8, 21.7, 34.1, 29.0, 28.4, 45.6, 51.7, 39.0, 60.0, 28.6, 32.1],
            data: [],
            tooltip: {
              valueSuffix: ' $'
            }

          },
          {
            name: 'Clicks',
            type: 'spline',
            // pointInterval: 1000 * 3600 * 24,
            data: [],
            tooltip: {
              valueSuffix: ''
            }
          }]
      },
      stat: [],
      headers: [
        {text: 'Date', align: 'start', value: 'date', class: 'sticky-header', sortable: false},
        {text: 'Clicks', align: 'start', value: 'clicks', class: 'sticky-header', sortable: false},
        {text: 'Revenue', align: 'start', value: 'revenue', class: 'sticky-header', sortable: false},
        {text: 'CPC', align: 'start', value: 'cpc', class: 'sticky-header', sortable: false}
      ],

    }
  },
  async mounted() {
    if (this.campaign === null) {
      this.createNew = true
    } else {
      this.campaignId = this.campaign.id
      this.campaignName = this.campaign.name
      this.imprint = this.campaign.imprint === 'yes'

      this.getKeywordsInfo()
      this.parseName()
      this.loadStatistic()
    }


  },

  computed: {
    allowCreatePixel() {
      if (this.pixelType === 'facebook') {
        if (this.pixelsNewFaceBook.event_name !== null &&
            (this.pixelsNewFaceBook.pixel_id !== null && typeof this.pixelsNewFaceBook.pixel_id === "string" && this.pixelsNewFaceBook.pixel_id.length > 10) &&
            (this.pixelsNewFaceBook.access_token !== null && typeof this.pixelsNewFaceBook.access_token === "string" && this.pixelsNewFaceBook.access_token.length > 10) &&
            this.pixelsNewFaceBook.deduplication_parameter !== null
        ) {
          return true
        }
      }
      if (this.pixelType === 'tiktok') {
        if (
            (
                (this.pixelsNewTikTok.events_s2s_conversion === 'no' &&
                    (typeof this.pixelsNewTikTok.event_name === "string"
                        && this.pixelsNewTikTok.event_name.length > 0)) ||
                (this.pixelsNewTikTok.events_s2s_conversion === 'yes')
            ) &&
            (this.pixelsNewTikTok.pixel_id !== null && typeof this.pixelsNewTikTok.pixel_id === 'string' && this.pixelsNewTikTok.pixel_id.length > 10) &&
            (this.pixelsNewTikTok.access_token !== null && typeof this.pixelsNewTikTok.access_token === "string" && this.pixelsNewTikTok.access_token.length > 10) &&
            this.pixelsNewTikTok.send_revenue !== null
        ) {
          return true
        }
      }
      return false
    },
    allowUpdatePixel() {
      // debugger
      if (!this.pixelIsNew) {
        if (this.pixelType === 'facebook') {
          if (this.pixels.event_name !== this.pixelsFromApi.event_name ||
              this.pixels.pixel_id !== this.pixelsFromApi.pixel_id ||
              this.pixels.access_token !== this.pixelsFromApi.access_token ||
              this.pixels.deduplication_parameter !== this.pixelsFromApi.deduplication_parameter ||
              this.pixels.send_revenue !== this.pixelsFromApi.send_revenue ||
              this.pixels.domain_verification !== this.pixelsFromApi.domain_verification
          ) {
            return true
          }

        }

        if (this.pixelType === 'tiktok') {
          if (
              (
                  (this.pixels.events_s2s_conversion === 'no' &&
                      (typeof this.pixels.event_name === "string"
                          && this.pixels.event_name !== this.pixelsFromApi.event_name)) ||
                  (this.pixels.events_s2s_conversion === 'yes' &&
                      this.pixels.events_s2s_conversion !== this.pixelsFromApi.events_s2s_conversion)
              ) ||
              this.pixels.pixel_id !== this.pixelsFromApi.pixel_id ||
              this.pixels.access_token !== this.pixelsFromApi.access_token ||
              this.pixels.send_revenue !== this.pixelsFromApi.send_revenue
          ) {
            return true
          }
        }
      }

      return false
    }
  },
  methods: {
    parseName() {
      const match = this.campaignName.match(/^(?<user>\w+)\s+-\s+(?<name>\w+)_(?<country>[a-zA-Z][a-zA-Z])\s?(?<postfix>.*)$/)
      if (match && match.length === 5) {
        this.user = match.groups.user
        this.name = match.groups.name
        this.country = match.groups.country
        this.postfix = match.groups.postfix
      }
    },

    loadStatistic() {
      // console.log(this.campaign)
      if (this.campaign.statistic) {

        this.campaign.statistic.days.buckets.forEach(b => {
          const date = DateTime.fromMillis(b.key, {zone: this.tz}).toSQLDate()
          this.highchartsOptions.xAxis[0].categories.push(date)

          const revenue = Math.round(b.revenue.value * 100) / 100

          this.highchartsOptions.series[0].data.push(revenue)
          this.highchartsOptions.series[1].data.push(b.clicks.value)

          const cpc = Math.round((revenue / b.clicks.value) * 100) / 100
          this.stat.push({
            date,
            clicks: b.clicks.value > 0 ? b.clicks.value : '',
            revenue: revenue > 0 ? revenue : '',
            cpc: cpc > 0 ? cpc : ''
          })
        })

        this.statisticIsReady = true
      }
    },

    async updateName() {
      // console.log('updateName', this.newCampaignName)
      this.loadingUpdateName = true
      try {
        const resp = await api.affNetworkUpdateName({
          network: this.$route.params.network,
          account: this.$route.params.account,
          id: this.campaignId,
          name: this.newCampaignName
        })
        // console.log('updateName', resp)
        if (resp.data.error) {
          this.isVisibleErrorDialog = true
          this.errorMessage = resp.data.msg
        } else {
          this.campaignName = this.newCampaignName
          this.$emit('update', true)
        }
      } catch (e) {
        console.error('updateName', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      }
      this.loadingUpdateName = false
    },

    async updateImprint() {
      this.loadingImprint = true
      // console.log('updateImprint', this.imprint)

      try {
        const resp = await api.affNetworkUpdateImprint({
          network: this.$route.params.network,
          account: this.$route.params.account,
          id: this.campaignId,
          name: this.campaignName,
          imprint: this.imprint ? 'yes' : 'no'
        })
        if (resp.data.error) {
          this.isVisibleErrorDialog = true
          this.errorMessage = resp.data.msg
          this.imprint = !this.imprint
          this.$emit('update', true)
        }
        console.log('updateImprint', resp)
      } catch (e) {
        console.log('updateImprint', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      }

      this.loadingImprint = false
    },

    async getKeywordsInfo() {
      this.loading = true
      this.keyWordsReady = false

      const request = {
        network: this.$route.params.network,
        account: this.$route.params.account,
        campaignId: this.campaignId
      }


      try {
        const resp = await api.affNetworkGetCampaignInfo(request)
        if (resp.data.keywords?.Keywords === 'null') {
          this.keyWords = null
          this.kwAmount = resp.data.keywords.KwAmount
        } else if (Array.isArray(resp.data.keywords?.Keywords)) {
          this.keyWords = resp.data.keywords.Keywords
          this.kwAmount = resp.data.keywords.KwAmount
        }


        if (resp.data.pixels) {
          this.pixelType = resp.data.pixels.source
          this.pixelsFromApi = Object.assign({}, resp.data.pixels)
          this.pixels = Object.assign({}, resp.data.pixels)
          // if (this.pixels.send_revenue === 'yes') {
          //   this.pixels.send_revenue = true
          // } else {
          //   this.pixels.send_revenue = false
          // }
          // if (this.pixels.events_s2s_conversion === 'yes') {
          //   this.pixels.events_s2s_conversion = true
          // } else {
          //   this.pixels.events_s2s_conversion = false
          // }
          this.pixelsIsReady = true
          this.pixelsAllowDelete = true
          this.pixelsAllowReset = true
        }

        this.isVisibleKeywords = true

      } catch (e) {
        console.error('getKeywordsInfo', e)
      } finally {
        this.loading = false
      }


    },

    async createNewCampaign() {
      this.loadingCreateCampaign = true
      // console.log('createNewCampaign', {
      //   network: 'Tonic',
      //   name: this.newCampaignName,
      //   country: this.country,
      //   offer: this.offer.name,
      //   offer_id: this.offer.id
      // })
      const r = {
        network: this.$route.params.network,
        account: this.$route.params.account,
        name: this.newCampaignName,
        country: this.country,
        offer: this.offer.name,
        offer_id: this.offer.id
      }

      if (this.enableDomain) {
        r.domain = this.domain + '.bond'
      }
      try {
        const resp = await api.affNetworkCreateNewCampaign(r)
        // console.log('updateName', resp)
        if (resp.data.error) {
          this.isVisibleErrorDialog = true
          this.errorMessage = resp.data.msg
        } else {
          this.campaignName = this.newCampaignName
          this.$emit('created', {name: this.campaignName})
          this.dialog = false
        }
      } catch (e) {
        console.error('updateName', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      } finally {
        this.loadingCreateCampaign = false
      }
    },

    async createPixel() {
      this.loadingCreatePixel = true
      const r = {
        network: this.$route.params.network,
        account: this.$route.params.account,
        id: this.campaignId,
        source: this.pixelType,
        pixel: this.pixels
      }
      try {
        const resp = await api.affNetworkCreatePixel(r)
        this.pixelsFromApi = r.pixel
        this.pixelIsNew = false
        this.pixelsAllowDelete = true
        this.pixelsAllowReset = true
        console.log('createPixel', resp)
      } catch (e) {
        console.error('createPixel', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      }

      this.loadingCreatePixel = false
    },

    async updatePixel() {
      this.loadingUpdatePixel = true
      const r = {
        network: this.$route.params.network,
        account: this.$route.params.account,
        id: this.campaignId,
        source: this.pixelType,
        pixel: this.pixels
      }
      try {
        const resp = await api.affNetworkUpdatePixel(r)
        this.pixelsFromApi = r.pixel
        // if (this.pixels.send_revenue === 'yes') {
        //   this.pixels.send_revenue = true
        // } else {
        //   this.pixels.send_revenue = false
        // }
        console.log('updatePixel', resp)
      } catch (e) {
        console.error('updatePixel', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      }

      this.loadingUpdatePixel = false
    },


    async deletePixel() {
      this.loadingDeletePixel = true
      const r = {
        network: this.$route.params.network,
        account: this.$route.params.account,
        id: this.campaignId
      }
      try {
        const resp = await api.affNetworkDeletePixel(r)
        console.log('deletePixel', resp)
        this.pixelType = null
        this.pixelsAllowDelete = false
      } catch (e) {
        console.error('deletePixel', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      }

      this.loadingDeletePixel = false
      this.confirmPixel = false

    },

    isAllowUpdateName() {
      if (this.user && this.name && this.country) {
        this.newCampaignName = this.user.trim() + ' - ' + this.name.trim() + '_' + this.country + (this.postfix ? ' ' + this.postfix.trim() : '')
        return this.campaignName !== this.newCampaignName
      }
      return false
    },

    isAllowCreateNewCampaign() {
      if (this.enableDomain && this.domain.length < 1) {
        return false
      }
      if (this.user && this.name && this.country && this.offer) {
        this.newCampaignName = this.user.trim() + ' - ' + this.name.trim() + '_' + this.country + (this.postfix ? ' ' + this.postfix.trim() : '')
        return this.campaignName !== this.newCampaignName
      }
      return false
    },


    onCopyKeywords(ev) {
      this.$emit('copy-keywords', ev)
      console.log('onCopyKeywords', ev)
      this.dialog = false
    },

    copyToClipboard(text) {
      this.$copyText(text, this.$refs.dialog)
      this.snackbarText = text
      this.snackbar = true
    }
  },
  watch: {
    dialog(v) {
      if (!v) {
        this.$emit('input', false)
      }
    },
    offer(value) {
      if (this.createNew) {

        if (value.name) {
          this.name = value.name.replace(/[-_|=`@!^&/\\#,+()$~%.'":*?<>{}\s]/g, '').replace(/PR$/, '')
        } else {
          this.name = value.replace(/[-_|=`@!^&/\\#,+()$~%.'":*?<>{}\s]/g, '').replace(/PR$/, '')
        }
        // console.log(`offer: ${value.name} -> ${this.name}`)
      }
    },
    pixelType(value, oldValue) {
      if (Object.keys(this.pixelsFromApi).length === 0) {
        this.pixelsIsReady = false
        if (value !== undefined || value !== null) {
          this.$nextTick(() => {
            if (oldValue === null) {
              this.pixelIsNew = true
            }
            if (value === 'facebook') {
              this.pixels = this.pixelsNewFaceBook
            }

            if (value === 'tiktok') {
              this.pixels = this.pixelsNewTikTok
            }
            this.pixelsIsReady = true
          })
        }
      }

    }
  }
}
</script>

<style scoped>

</style>